import React from 'react';
import PropTypes from 'prop-types';

export default function PredictionsIcon({ className = '' }) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g clipPath="url(#clip0_18478_7822)">
                <path
                    d="M12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8C14.2091 8 16 9.79086 16 12C16 12.5523 16.4477 13 17 13C17.5523 13 18 12.5523 18 12C18 8.68629 15.3137 6 12 6Z"
                    fill="#0059FF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 12C3 13.8767 3.57538 15.6211 4.55881 17.0638L3.1368 19.4951C2.95592 19.8044 2.95431 20.1868 3.13257 20.4976C3.31083 20.8083 3.64173 21 4 21H20C20.3583 21 20.6892 20.8083 20.8674 20.4976C21.0457 20.1868 21.0441 19.8044 20.8632 19.4951L19.4412 17.0638C20.4246 15.6211 21 13.8767 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12C19 13.4883 18.5365 14.8662 17.7454 16H6.25457C5.46348 14.8662 5 13.4883 5 12ZM5.74336 19L6.32824 18H17.6718L18.2566 19H5.74336Z"
                    fill="#0059FF"
                />
            </g>
        </svg>
    );
}

PredictionsIcon.propTypes = {
    className: PropTypes.string
};
