import React from 'react';
import PropTypes from 'prop-types';

export default function TriviaIcon({ className = '' }) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M10.65 15.4C10.65 14.05 10.7708 13.0792 11.0125 12.4875C11.2542 11.8958 11.7667 11.25 12.55 10.55C13.2333 9.94999 13.7542 9.42916 14.1125 8.98749C14.4708 8.54583 14.65 8.04166 14.65 7.47499C14.65 6.79166 14.4208 6.22499 13.9625 5.77499C13.5042 5.32499 12.8667 5.09999 12.05 5.09999C11.2 5.09999 10.5542 5.35833 10.1125 5.87499C9.67083 6.39166 9.35833 6.91666 9.175 7.44999L6.6 6.34999C6.95 5.28333 7.59167 4.35833 8.525 3.57499C9.45833 2.79166 10.6333 2.39999 12.05 2.39999C13.8 2.39999 15.1458 2.88749 16.0875 3.86249C17.0292 4.83749 17.5 6.00833 17.5 7.37499C17.5 8.20833 17.3208 8.92083 16.9625 9.51249C16.6042 10.1042 16.0417 10.775 15.275 11.525C14.4583 12.3083 13.9625 12.9042 13.7875 13.3125C13.6125 13.7208 13.525 14.4167 13.525 15.4H10.65ZM12.05 21.4C11.5 21.4 11.0292 21.2042 10.6375 20.8125C10.2458 20.4208 10.05 19.95 10.05 19.4C10.05 18.85 10.2458 18.3792 10.6375 17.9875C11.0292 17.5958 11.5 17.4 12.05 17.4C12.6 17.4 13.0708 17.5958 13.4625 17.9875C13.8542 18.3792 14.05 18.85 14.05 19.4C14.05 19.95 13.8542 20.4208 13.4625 20.8125C13.0708 21.2042 12.6 21.4 12.05 21.4Z"
                fill="#0059FF"
            />
        </svg>
    );
}

TriviaIcon.propTypes = {
    className: PropTypes.string
};
