import React from 'react';
import PropTypes from 'prop-types';

export default function DataCaptureIcon({ className = '' }) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M3.4105 2.4C2.8525 2.4 2.4 2.8524 2.4 3.4105c0 .5581.4524 1.0106 1.0105 1.0106h12.1263c.5581 0 1.0106-.4525 1.0106-1.0106 0-.558-.4525-1.0105-1.0106-1.0105H3.4105ZM9.4737 21.2632v-2.0477c0-.0943.0337-.1752.101-.2425l7.3489-7.3489 2.526 2.526-7.3557 7.3489c-.0606.0673-.1481.101-.2357.101H9.8105a.3335.3335 0 0 1-.3368-.3368ZM21.403 11.2469a.6709.6709 0 0 1 0 .9498l-1.2327 1.2326-2.526-2.5259 1.2327-1.2327a.6709.6709 0 0 1 .9498 0l1.5762 1.5762ZM2.4 7.4526c0-.558.4524-1.0105 1.0105-1.0105h12.1263c.5581 0 1.0106.4524 1.0106 1.0105 0 .5581-.4525 1.0106-1.0106 1.0106H3.4105c-.558 0-1.0105-.4525-1.0105-1.0106ZM3.4105 10.4842c-.558 0-1.0105.4524-1.0105 1.0105 0 .5581.4524 1.0106 1.0105 1.0106h6.0632c.5581 0 1.0105-.4525 1.0105-1.0106 0-.5581-.4524-1.0105-1.0105-1.0105H3.4105Z"
                fill="#0059FF"
            />
        </svg>
    );
}

DataCaptureIcon.propTypes = {
    className: PropTypes.string
};
